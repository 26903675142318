import React from 'react';
import Moble from '../img/banner/Mobile-banner.jpg';
import app from '../img/banner/Mobile-app.png';
import { IoIosCheckbox } from "react-icons/io";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './style.css';


function mobile_app_testing() {
  return (
    <>
     <div className="banner">
      <img src={Moble} alt="" width={"100%"} />
      </div>
      <div className="container">
        <div className="row SAP-Overview">
        <div className="col-lg-8 service-content">
            <h4>Mobile Apps Development</h4>
            <p>With mobility becoming a key responsibility within enterprise IT, organizations are having to develop large and complex enterprise mobile applications to align with their business objectives.</p>
            <p>Building customized mobile apps for managing enterprise functions is complex. Enterprise mobile applications need to be designed for deployment on different networks, operating systems and devices, adding to their complexity.</p>
            <p>We identify the needs of a constantly evolving technology environment, enable mobility-driven business transformation, and provide best-suited mobile application development solutions that are scalable, robust, secure and flexible for enterprise management.
            <br /><br />We provide a complete stream of enterprise mobile application development services and solutions to design, build, and deploy native and hybrid mobile apps.</p>
          </div>
          <div className="col-lg-4 about-img">
            <img src={app} alt="Mobile-app" width={"100%"} />
          </div>

          <div className="col-lg-12 overview-content wow fadeInUp">
              <h6>Our enterprise mobile app services include: </h6>
              <p><strong>Architecture definition and development</strong></p>
              <ul>
              <li><IoIosCheckbox className="custom-icon" /> Enterprise mobile architecture definition, including strategies to integrate existing legacy applications</li>
              <li><IoIosCheckbox className="custom-icon" /> Application UI architecture development</li>
              <li><IoIosCheckbox className="custom-icon" /> Compliance with general and platform-specific usability standards, recommendations, and guidelines</li>
              </ul>
              
              <p><strong>Enterprise Mobile App development</strong></p>
              <ul>
              <li><IoIosCheckbox className="custom-icon" /> Customized mobile solutions and mobile application development for different app platforms – iOS development, Android development, Windows/Windows 8 app development</li>
              <li><IoIosCheckbox className="custom-icon" /> HTML5 web app development</li>
              <li><IoIosCheckbox className="custom-icon" /> Server-side backend development, including push notifications and web services.</li>
              </ul>

                <p><strong>Consumer Mobile App Development</strong></p>
                <p>Any exceptional app has a design that is intuitive, simple and engaging. Our in-house designers are committed to creating app interfaces that are functional, easy to use and can unite all technical aspects of the app with a design that is complimentary and logical.</p>
              <ul>
              <li><IoIosCheckbox className="custom-icon" />Native application development</li>
              <li><IoIosCheckbox className="custom-icon" /> HTML5 application development</li>
              <li><IoIosCheckbox className="custom-icon" /> Hybrid application development</li>
              <li><IoIosCheckbox className="custom-icon" />Cross-platform application development</li>
              </ul>
        </div>

        <div className="col-lg-12 overview-content wow fadeInUp">
              <h4>Mobile Apps Testing</h4>
              <p>Mobile devices are being used by your employees, customers and partners to access your applications and information. This proliferation of mobile device access makes it appealing for hackers to get unauthorized access to valuable enterprise and personal data. Pacecom, through regular testing and constant plugging and patching against ever emerging vulnerabilities and threats, creates a stable and secure base for your enterprise mobile environment. Furthermore, your applications should be able to run uninterrupted across multiple platforms and devices, as well as on open source operating systems. This requires both extensive and intensive testing of an application’s or device’s functionality on a multitude of smart devices or operating systems.</p>
              <p>Pacecom understands such nuances and is able to assess your current and future Mobility requirements. Our team of testing experts provides security designed to address critical attacks and vulnerabilities at the source code level. We ensure app functionality and optimum performance across platforms and technologies as diverse as iOS, Blackberry, Android, and Windows. Our deep expertise and ability to handle multiple nuances ensures your mobile apps have a secure environment, and eliminates costs and risks involved in rewriting app codes.</p>
              <p>Our team is familiar with all mobile operating systems and is equally experienced in back-end server and web technologies. The testing team engages at the start of each mobile development project to define an appropriate and effective test strategy. Our goal is to ensure the final product is robust, and our solution accounts for any possible edge cases. Every app is tested at multiple stages through interim builds and a number of industry standard tools are deployed to ensure unrivalled and seamless quality.</p>
              <p>We believe that mobile development and integration with the enterprise is a mission critical process. An unplanned or adhoc approach towards mobile testing can impede business and have rigorous implications on critical quality attributes such as accuracy, consistency, security and performance. That’s why our mobile testing services include:</p>
            
              <ul>
              <li><IoIosCheckbox className="custom-icon" /> End-to-end process checks and compliance to standards ensure security and regulatory compliance</li>
              <li><IoIosCheckbox className="custom-icon" /> In-depth analysis of mobile device and application vulnerabilities and misconfigurations at the source code level</li>
              <li><IoIosCheckbox className="custom-icon" /> Testing mobile devices and native, web or hybrid apps on multiple platforms for functionality, performance, usability, accessibility, and design</li>
               <li><IoIosCheckbox className="custom-icon" /> Testing enterprise level deployment of devices and business applications</li>
               <li><IoIosCheckbox className="custom-icon" /> Provision for detailed steps for remediation without compromising business continuity</li>
              </ul>
        </div>

        </div>
        </div>
    </>
  )
}

export default mobile_app_testing
