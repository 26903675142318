import React from 'react';
import open from '../img/banner/openings.jpg';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

function current_openings() {
  return (
    <>
    <div className="banner">
      <img src={open} alt="" width={"100%"}/>
      </div>

      <div className="container">
        <div className="row SAP-Overview">
        <div className="col-lg-12 overview-content wow fadeInUp">
          
            <h4>Careers</h4>
            <h6>Pacecom is looking for experienced professionals in the following areas:</h6>
            {/* <p>Email your resume to careers@pacecom.co.in</p> */}
            <h5>No careers found</h5>
          
        </div>
        </div>
        </div>
    </>
  )
}

export default current_openings
