import React, { useEffect } from 'react';
import {Route,BrowserRouter,Switch} from 'react-router-dom';
import Home from './components/Home';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import Topnavbar from './components/Topnavbar';
import automotive from './components/automotive';
import solutions from './components/solutions';
import product_engineering from './components/product_engineering';
import mobile_app_testing from './components/mobile_app_testing';
import product_testing from './components/product_testing';
import test_automation from './components/test_automation';
import talent_acquisition_staffing from './components/talent_acquisition_staffing';
import career_growth from './components/career_growth';
import current_openings from './components/current_openings';
import consulting from './components/consulting';
import Contact from './components/Contact';
import Navbar from './components/Navbar';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
// import { Navbar } from 'react-bootstrap';


const App = () => {
  
  // const apiUrl = process.env.REACT_APP_API_URL;
  // console.log(apiUrl);

  useEffect(() => {
    AOS.init({
      // Global settings here
    });
  }, []);

  return (
    <BrowserRouter>
    <>
    <ScrollToTop />
       <Topnavbar />
      <Navbar />
      {/* <BannerSlider /> */}
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/automotive" component={automotive} />
        <Route path="/solutions" component={solutions} />
        <Route path="/product_engineering" component={product_engineering} />
        <Route path="/mobile_app_testing" component={mobile_app_testing} />
        <Route path="/product_testing" component={product_testing} />
        <Route path="/test_automation" component={test_automation} />
        <Route path="/talent_acquisition_staffing" component={talent_acquisition_staffing} />
        <Route path="/consulting" component={consulting} />
        <Route path="/career_growth" component={career_growth} />
        <Route path="/current_openings" component={current_openings} />
        <Route path="/Contact" component={Contact} />
      </Switch>
    </>
    <Footer />
    </BrowserRouter>
  )
}

export default App


