import React from 'react';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'aos/dist/aos.css';
import { FaLinkedinIn, FaFacebookF  } from "react-icons/fa";
import { IoIosMap, IoIosMail, IoMdCall, IoLogoTwitter } from "react-icons/io";

const Topnavbar = () => {
  return (
    <>

    <div className='topnavbar'>
    <div className='topnavbarleft'>
    <div className='social-icon'><a href='#'>< IoIosMail size={24} style={{color: "#fb9f01"}} />info@pacecom.co.in</a></div>
    <div className='social-icon'><a href='#'>< IoMdCall size={24} style={{color: "#fb9f01"}} /> +91 7019944659</a></div>
    <div className='social-icon'><a href='https://www.google.com/maps/place/Pacecom+Technologies+Pvt+Ltd/@12.9223501,77.5603905,17.21z/data=!4m5!3m4!1s0x0:0xba4a1dd4ca3b0ade!8m2!3d12.9225875!4d77.5602284' target='blank'><IoIosMap size={24} style={{color: "#fb9f01"}} /> Banashankari 3rd Stage, Bengaluru</a></div>
    <div className='topnavsocial'> 
      <span>Social share:</span> 
      <span className='social-icon'><a href="https://www.facebook.com/pacecomtechnologies" target='blank'><FaFacebookF /></a></span>
      <span className='social-icon'><a href="https://www.linkedin.com/company/pacecom-technologies-pvt-ltd" target='blank'><FaLinkedinIn /></a></span>
      <span className='social-icon'><a href="https://twitter.com/PacecomT" target='blank'><IoLogoTwitter /></a></span>
      </div>
    </div>
    </div>

    </>
  )
}

export default Topnavbar
