import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './style.css';
import testing from '../img/banner/product-testing.jpg';

function product_testing() {
  return (
    <>

<div className="banner">
<img src={testing} alt="" width={"100%"} />
</div>

<div className="container">
<div className="row SAP-Overview">
<div className="col-lg-12 overview-content wow fadeInUp">
          <div className="col-lg-12 service-content">
            <h4>Product Testing</h4>
            <p>Comprehensive Testing is crucial before a product is deployed into the real world environment. As software products get complex, pack more features, and promise functionality on multiple platforms and environments, it is essential that they deliver a superior, glitch-free performance.</p>
          <p>At Pacecom, we offer exhaustive Product Testing services that will provide customers with valuable inputs enabling them to deliver successful products in the marketplace with cost and time advantage to our customer.</p>
         <p>nd-to-end Testing Services –We use the latest of tools, techniques and methodologies to test a product exhaustively to determine system dependencies, stability, security aspects, performance, ease of use, deployability across platforms etc.</p>
          </div>
          
          </div>
</div>
</div>

    </>
  )
}

export default product_testing
