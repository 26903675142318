import React from 'react';
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function footer() {
  return (
    <>
<footer className='footer-bg'>
<Container >
      <Row>
        <Col sm={'9'} xs={12} className="footer"><p>Copyright © 2024 <span style={{color: "#FFA100"}}><a href='#'>Pacecom.co</a></span> All Rights Reserved</p></Col>
        <Col sm={'3'} xs={12} className="footer-icons">
      <span className="foter-bg"><a href='https://www.facebook.com/pacecomtechnologies' target='blank'><FaFacebookF /></a></span>
      <span className="foter-bg"><a href='https://x.com/i/flow/login?redirect_after_login=%2FPacecomT' target='blank'><FaTwitter /></a></span>
      <span className="foter-bg"><a href='https://www.linkedin.com/company/pacecom-technologies-pvt-ltd/mycompany/' target='blank'>< FaLinkedinIn /></a></span>
    </Col>
      </Row>
    </Container>
    </footer>

    </>
  )
}

export default footer
