import React from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './style.css';
import logo from '../img/logo/PacecomLogo.png';
import {Link} from 'react-router-dom';
import {HashLink } from "react-router-hash-link";
function BasicExample() {
  //const apiUrl = process.env.REACT_APP_API_URL;

  return (
    <>
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Link to="/"><img src={logo} alt="logo" width={"100%"} /></Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <HashLink spy="true" smooth={true} offset={-70} duration={500} className='nav-item-style'  to="/#About"> 
            ABOUT US </HashLink>
            <NavDropdown title="SERVICES" id="basic-nav-dropdown" scroll="true">
              <NavDropdown.Item><Link  to={'/automotive'} scroll="true"> Automotive </Link></NavDropdown.Item>
              <NavDropdown.Item><Link  to={`/solutions` } > SAP Solutions </Link></NavDropdown.Item>
              <NavDropdown.Item><Link  to='/product_engineering'>Product Engineering </Link></NavDropdown.Item>
              <NavDropdown.Item><Link  to={`/mobile_app_testing`}> Mobile Apps and Testing </Link></NavDropdown.Item>
              <NavDropdown.Item><Link  to={`/product_testing`}> Product Testing </Link></NavDropdown.Item>
              <NavDropdown.Item><Link  to={`/test_automation`}> Test Automation </Link></NavDropdown.Item>
              <NavDropdown.Item><Link  to={`/talent_acquisition_staffing`}> Talent Acquisition and Staffing </Link></NavDropdown.Item>
              <NavDropdown.Item><Link  to={`/consulting`}> Consulting </Link></NavDropdown.Item>
            </NavDropdown>
           <HashLink spy="true" smooth={true} offset={-70} duration={500} className='nav-item-style'  to="/#Products"> PRODUCTS </HashLink>
            <NavDropdown title="CAREER" id="basic-nav-dropdown">
              <NavDropdown.Item><Link  to={`/career_growth`}> Career Growth </Link></NavDropdown.Item>
              <NavDropdown.Item><Link  to={`/current_openings`}> Current Openings </Link></NavDropdown.Item>
            </NavDropdown>
            <Link className='nav-item-style' to={`/Contact`}> CONTACT US </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
  );
}

export default BasicExample;