import { useRef } from 'react';
import React from 'react';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { IoIosPin, IoIosMail, IoMdCall } from "react-icons/io";
import diagram from '../img/banner/location1.jpg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'; 
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useFormik } from "formik";
import { ContactSchema } from "../schemas";
import emailjs from '@emailjs/browser';

  const Contact = () => {

    const form = useRef();

    const sendEmail = (e) => {

      e.preventDefault();

      emailjs
      .sendForm('service_6xppyep', 'template_4at2ny6', form.current, {
        publicKey: 'XqSy6yApiH4SK-uAp',
      })

      .then(
        () => {
          console.log('SUCCESS!');
          // alert('Success')
        },
        (error) => {
          console.log('FAILED...', error.text);
           
        },
      );
    };

const [result, setResult] = React.useState("");

  const initialValues = {
    name: "",
    mobile: "",
    email: "",
    textarea: "",
  };
  
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
      useFormik({
        initialValues,
        validationSchema: ContactSchema,
        onSubmit: (values, action) => {
          console.log('Form data', values);
          action.resetForm();
        },
        
      });
    console.log(
      errors
    );
 
  return (
    <>
      <div className="banner">
  <img src={diagram} width={"100%"} alt="" />
</div>
    <div className="container" data-aos="fade-up">
      <div className='row'>
<Tab.Container id="left-tabs-example" defaultActiveKey="Bangalore" data-aos="fade-up">
      <Row>
        <Col xs={12} md={3}>
          <Nav variant="pills" className="flex-column tab-style">
            <Nav.Item>
              <Nav.Link className='nav-tab' eventKey="Bangalore">Bangalore</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className='nav-tab' eventKey="Mysore">Mysore</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className='nav-tab' eventKey="Sydney">Sydney</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className='nav-tab' eventKey="Melbourne">Melbourne</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={9}>
          <Tab.Content>
            <Tab.Pane eventKey="Bangalore" >
            <p className='corporate-heading' align="center"><b><u>Corporate Office</u></b></p>
            <Row>
            <Col md={6}>
                <span className='iconstyle'><IoIosPin /></span>
                <p className='text-center'>609, 80 Feet Rd, Next to Dmart, <br /> 7 Block, CG Chinnappa Naidu Layout, <br />Banashankari 3rd Stage, Banashankari, <br/>Bengaluru, Karnataka 560085</p>
              </Col>
              <Col md={3} className='info text-center'>
              <span className='iconstyle'><IoIosMail /></span>
                <p> info@pacecom.co.in</p>
              </Col>
              <Col md={3} className='info text-center'>
              <span className='iconstyle'><IoMdCall /></span>
                <p>+91 7019944659</p>
              </Col>
              <div className="clear"></div>
              <div className="col-md-12 hidden-xs hidden-sm info">
               <iframe width="100%" height="500" src="https://maps.google.com/maps?q=pacecom%20technologies%20devegowda%20petrol%20bunk&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" title='maps'></iframe>
              </div>  
             </Row>
            </Tab.Pane>
            <Tab.Pane eventKey="Mysore">
            <div className="row" style={{padding:"20px"}}>
              <div className="col-md-4 info text-center">
              <span className='iconstyle'><IoIosPin /></span>
                <p>Mysore,<br />Karnataka 560085</p>
              </div>
              <div className="col-md-4 info text-center">
              <span className='iconstyle'><IoIosMail /></span>
                <p> info@pacecom.co.in</p>
              </div>
              <div className="col-md-4 info text-center">
              <span className='iconstyle'><IoMdCall /></span>
                <p>+61 416018581</p>
              </div>
              <div className="clear"></div>
              <div className="col-md-12 hidden-xs hidden-sm info">
               <iframe width="100%" height="500" src="https://maps.google.com/maps?q=mysore%20nazarabad&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" title='maps'></iframe>
              </div>
             </div>
            </Tab.Pane>
            <Tab.Pane eventKey="Sydney">
              <Row style={{padding:"20px"}}>
                <Col md={4} className='info text-center'>
              <span className='iconstyle'><IoIosPin /></span>
                <p>Sydney,<br />101, Caprera Road Northmead NSW -2152</p>
              </Col>
              <Col md={4} className='info text-center'>
              <span className='iconstyle'><IoIosMail /></span>
                <p> satishb@pacecom.net.au</p>
              </Col>
              <Col md={4} className='info text-center'>
              <span className='iconstyle'><IoMdCall /></span>
                <p>+61 416018581</p>
              </Col>
            <div className="clear"></div>
            <Col md={12} className='hidden-xs hidden-sm info'>
               <iframe width="100%" height="500" src="https://maps.google.com/maps?q=%20101%20Caprera%20Rd%2C%20Northmead%20NSW%20&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
               </Col>
            </Row>
            </Tab.Pane>
            <Tab.Pane eventKey="Melbourne">
              <Row style={{padding:"20px"}}>
              <Col md={4} className='info text-center'>
              <span className='iconstyle'><IoIosPin /></span>
                <p>Melbourne<br /></p>
              </Col>
              <Col md={4} className='info text-center'>
              <span className='iconstyle'><IoIosMail /></span>
                <p> chnadrab@pacecom.net.au</p>
              </Col>
              <Col md={4} className='info text-center'>
              <span className='iconstyle'><IoMdCall /></span>
                <p>+61 411185860</p>
              </Col>
             <div className="clear"></div>
             <Col md={12} className='hidden-xs hidden-sm info'>
               <iframe width="100%" height="500" src="https://maps.google.com/maps?q=Wellington%20Parade%2C%20East%20Melbourne%20VIC%203002%2C%20Australia&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" title='map'></iframe>
              </Col>
            </Row>
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
    </div>
    </div> 
    <div>

    <Form ref={form} className='formstyle' method='post' onSubmit={(e) => {handleSubmit(e); sendEmail(e);}}>
    <Row>
    <Col sm={6}>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Control type="Name" autoComplete="off" name="name" placeholder="Your Name" value={values.name} onChange={handleChange} onBlur={handleBlur} />
      </Form.Group>
      {errors.name && touched.name ? (
        <p className="form-error">{errors.name}</p>
      ) : null}
      </Col>
      <Col sm={6}>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Control type="text" name="mobile" placeholder="Your Mobile" value={values.mobile} onChange={handleChange} onBlur={handleBlur} />
      </Form.Group>
      {errors.mobile && touched.mobile ? (
        <p className="form-error">{errors.mobile}</p>
       ) : null}
      
      </Col>
      </Row>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Control type="text" name="email" placeholder="Your Email Id" value={values.email} onChange={handleChange} onBlur={handleBlur} />
      </Form.Group>
      {errors.email && touched.email ? (
        <p className="form-error">{errors.email}</p>
        ) : null}
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1" >
        <Form.Control className="textarea" name="textarea" placeholder="Message" rows={4} value={values.textarea} onChange={handleChange} onBlur={handleBlur} />
      </Form.Group>
      {errors.textarea && touched.textarea ? (
        <p className="form-error">{errors.textarea}</p>
        ) : null}
      <div className='text-center'>
      <Button className="d-grid gap-2" variant="outline-primary" type="submit">Submit</Button>
      </div>
    </Form>
    </div>
 
</>


  )

};

export default Contact
