import React from 'react';
import consult from '../img/banner/consulting.jpg';
import { IoIosCheckbox } from "react-icons/io";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './style.css';

function consulting() {
  return (
    <>
    <div className="banner">
      <img src={consult} alt="" width={"100%"} />
      </div>

      <div className="container">
        <div className="row SAP-Overview">
        <div className="col-lg-12 overview-content wow fadeInUp">
          <div className="col-lg-12 service-content">
            <h4>Consulting</h4>
            <p>Business enterprises are adopting mobility across the board to drive efficiency, improve customer and employee satisfaction, provide anytime, anywhere access to business processes and also to be at the cutting edge of the technology revolution. Organizations are using mobility as a strategic tool to automate and optimize business processes. User adoption rates would ultimately determine the success of mobile app deployments. Pacecom helps organizations develop end-to-end mobility strategies that would ultimately maximize user adoptions and successful mobile apps. The steps involved in developing the right mobility strategy for an organization are:</p>
            <ul>
              <li><IoIosCheckbox className="custom-icon" />Identification of mobile enablement opportunities</li>
              <li><IoIosCheckbox className="custom-icon" /> Determination of right technologies, and</li>
              <li><IoIosCheckbox className="custom-icon" /> Integration of mobile apps with enterprise applications, social media, cloud and analytics</li>
              </ul>
          </div>
         
         
          </div>
             <div className="col-lg-12 overview-content wow fadeInUp">
              <h6>Identification of mobile enablement opportunities: </h6>
              <p>Pacecom, with its vast experience in enterprise mobility, is in a great position to advise on and identify opportunities to deploy mobile apps for customers, partners, suppliers, and employees. The apps would also be spread across front office and back office operations and the supply chain. Pacecom will recommend:</p>
              <ul>
              <li><IoIosCheckbox className="custom-icon" /> The right approach to embark on a mobile strategy for the first time</li>
              <li><IoIosCheckbox className="custom-icon" /> The right approach to reach maturity levels with mobile apps</li>
            </ul>
            <p>Pacecom experts will determine the optimal level of functions and processes that need to reside on the mobile platform and also the supportive backend functions.</p>
             <h6>Determination of right technologies:  </h6>
             <p>Pacecom will help enterprises choose and deploy the right technologies from myriad choices of technologies and tools that are required to enable mobility.</p>
              <ul>
              <li><IoIosCheckbox className="custom-icon" /> Hybrid vs. Native apps: Key criteria for choosing between Hybrid and Native mobile apps</li>
              <li><IoIosCheckbox className="custom-icon" /> Mobile site : Methods to build and integrate mobile sites into mobility strategy</li>
              <li><IoIosCheckbox className="custom-icon" /> UX/UI strategy for better adoption and usage of mobile apps</li>
              <li><IoIosCheckbox className="custom-icon" /> Right tools and technologies for analytics and customer engagements</li>
                <li><IoIosCheckbox className="custom-icon" /> Right tools and technologies for securing the app and its communications with external apps</li>
            </ul>
             <h6>Integration of mobile apps with enterprise applications, social media, cloud and analytics :</h6>
              <p>Today’s mobile apps integrate with enterprise applications, social media, cloud and analytics platforms. Pacecom mobility consultants help enterprises choose the right integration technologies from among the plethora of choices available. With our in-depth understanding of SMAC technologies, we are building connected apps towards leveraging your business.</p>
        </div>
        </div>

      </div>
    </>
  )
}

export default consulting
