import React from 'react';
import career from '../img/banner/career.jpg';
import { IoIosCheckbox } from "react-icons/io";

function talent_acquisition_staffing() {
  return (
    <>
     <div className="banner">
      <img src={career} alt="" width={"100%"} />
      </div>
      <div className="container">
        <div className="row SAP-Overview">
        <div className="col-lg-12 overview-content wow fadeInUp">
          <div className="col-lg-12 service-content">
            <h4>Talent Acquisition And Staffing</h4>
            <p>We are an organization in the business of people serving people.
                Pacecom is an IT services company that offers strategic IT HR managed solutions –Fulltime Hiring, Temporary staffing, Staff augmentation, Contract to hire and position resources for Turn key projects.</p>
        
          </div>
         
         
          </div>
             <div className="col-lg-12 overview-content wow fadeInUp">
              <h6>Our Strength </h6>
              <ul>
              <li><IoIosCheckbox className="custom-icon" /> Over 19 years of experience in Strategic IT HR and Managed solutions</li>
              <li><IoIosCheckbox className="custom-icon" /> India operations at Bangalore</li>
              <li><IoIosCheckbox className="custom-icon" /> Overseas-Melbourne and Sydney, Australia</li>
              <li><IoIosCheckbox className="custom-icon" /> Exponential growth of employees and the revenue</li>
              <li><IoIosCheckbox className="custom-icon" /> Key process-Consulting Business</li>
            </ul>
             <h6>Our Drives and growth </h6>
              <ul>
              <li><IoIosCheckbox className="custom-icon" /> Relationships</li>
              <li><IoIosCheckbox className="custom-icon" /> Recognition</li>
              <li><IoIosCheckbox className="custom-icon" /> Innovation</li>
              <li><IoIosCheckbox className="custom-icon" /> Quality</li>
                <li><IoIosCheckbox className="custom-icon" /> Sustaining growth</li>
            </ul>
             <h6>Our offerings</h6>
              <ul>
              <li><IoIosCheckbox className="custom-icon" /> IT professional services for short and long term needs</li>
              <li><IoIosCheckbox className="custom-icon" /> Employee management services (Screening and Deployment)</li>
              <li><IoIosCheckbox className="custom-icon" /> Counselling/performance management</li>
              <li><IoIosCheckbox className="custom-icon" /> Continuity management</li>
              <li><IoIosCheckbox className="custom-icon" /> Software development services and ODCss</li>
            </ul> 
        </div>
        </div>

      </div>

    </>
  )
}

export default talent_acquisition_staffing
