import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './style.css';
import car from '../img/banner/careers.jpg';
import { IoIosCheckbox } from "react-icons/io";

function career_growth() {
  return (
    <>
      <div className="banner">
      <img src={car} alt="" width={"100%"} />
      </div> 

           <div className="container">
        <div className="row SAP-Overview">
        <div className="col-lg-12 overview-content wow fadeInUp">
          <div className="col-lg-8 service-content">
            <h4>Career Growth</h4>
            <p>PACECOM is one of the top tier IT services companies when it comes to “Collaborative Innovation” and “Global Delivery Model’</p>
            <p>PACECOM headquartered in Bangalore was founded in 1999. Originally, PACECOM was created with a vision of forming partnership with clients worldwide to assist them in the finding of high quality software engineers. Subsequently it has transformed itself into a leading IT services organization capable of providing consulting, Enterprise Application Integration (EAI), Systems Integration, and application & business process outsourcing solutions to clients in major industry verticals worldwide.</p>
          </div>
           <div className="col-lg-4 about-img">
            
          </div>
          </div>
             <div className="col-lg-12 overview-content wow fadeInUp">
              <h6>At PACECOM, you have the opportunity to: </h6>
              <ul>
              <li><IoIosCheckbox className="custom-icon" /> Benefit from merit-based promotion, determined by your performance, skills and willingness to take on greater responsibility.</li>
              <li><IoIosCheckbox className="custom-icon" /> Make important contacts, working with top experts and making the most of our buddy system. Your coaches and mentors will work with you to help you reach your potential.</li>
              <li><IoIosCheckbox className="custom-icon" /> Build your knowledge base through a wide range of training and experiences.</li>
              <li><IoIosCheckbox className="custom-icon" /> Contribute to a global team that acts with passion, commitment and integrity.</li>
            </ul>
            <p>PACECOM’s people are our key asset, essential to our success. We invest in them accordingly. Our declared commitments to our professionals are designed to develop their potential and help them achieve their goals with each other’s support.</p>
        </div>
           <div className="col-lg-12 overview-content wow fadeInUp">
              <h6>Our commitments include: </h6>
              <ul>
              <li><IoIosCheckbox className="custom-icon" /> Respect for diversity</li>
              <li><IoIosCheckbox className="custom-icon" /> Career flexibility, development and constructive recognition</li>
              <li><IoIosCheckbox className="custom-icon" /> Free exchange of ideas</li>
             </ul>
        </div>
          <div className="col-lg-12 overview-content wow fadeInUp">
              <h6>Our Onboarding Process</h6>
              <p>We take great care to ensure our new colleagues immediately understand and feel involved in the collaborative experience. Each new team member follows an individually-tailored onboarding process which, depending on country and role, can include up to 12 months of interactive sessions and support from a personal mentor.</p>
          </div>
           <div className="col-lg-12 overview-content wow fadeInUp">
              <h6>Learning and Development</h6>
              <p>We offer numerous training opportunities which enable our people to build on their talents and capabilities, ensuring their knowledge and skills keep pace with market needs.</p>
          </div>
          <div className="col-lg-12 overview-content wow fadeInUp">
              <h6>Diversity</h6>
              <p>Diversity is important at PACECOM. It inspires our wide-ranging thought processes and reflects the composition of our client base. We appreciate difference and value the contributions of all our team members. We strive to create environments where all participants feel comfortable.</p>
          </div>
          <div className="col-lg-12 overview-content wow fadeInUp">
              <h6>Community Involvement</h6>
              <p>We take our corporate responsibilities seriously and encourage our people to become involved with the communities around them.</p>
          </div>
        </div>

      </div> 
    </>
  )
}

export default career_growth
