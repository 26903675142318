import React, { } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './style.css';
import product from '../img/banner/product-Enginnering.jpg';
import engg from '../img/banner/Product-engg-s.jpg';
import { IoIosCheckbox } from "react-icons/io";


const product_engineering = () => {

  return (
    <>
     <div className="banner">
      <img src={product} alt="" width={"100%"} />
      </div>
      <div className="container">
      <div className="row SAP-Overview">
      <div className="col-lg-8 service-content">
      <h4>Product Engineering</h4>
            <p>We offer software product design, software systems development services and Centers of Excellence (CoE) for key technology stacks. Our development services span the development phase and the entire software support lifecycle including – Ideation, Product Architecture, Design, Testing and QA, Migration and Porting.</p>
            <h6>Considering the changes in the product architecture, you should address these challenges: </h6>
              <ul>
              <li><IoIosCheckbox className="custom-icon" /><strong>Framework design</strong> – A framework forms a crucial aspect of product development. Our teams develop a robust and flexible framework to ensure a product that delivers to expectations.</li>
              <li><IoIosCheckbox className="custom-icon" /> <strong>Development services</strong> – We have proven expertise in product design, software systems and product development services and Centers of Excellence (CoE) for key technology stacks.</li>
              </ul>
      </div>
      <div className="col-lg-4 about-img">
            <img src={engg} alt="" width={370}/>
          </div>

          <div className="col-lg-12 overview-content wow fadeInUp">
             <ul>
             <li><IoIosCheckbox className="custom-icon" /> <strong>Re-engineering, hot fixes, patches</strong> – Our engineering teams are geared to re-engineer products for better performance, offer hot fixes to address bugs, and develop patches to address compliance/ performance issues across various platforms.</li>
             <li><IoIosCheckbox className="custom-icon" /> <strong>Porting and Migration</strong> – Our teams have the required experience and skills to offer effective porting and migration to various popular platforms towards achieving better delivery and performance.</li>
           </ul>
       </div>
      </div>
      </div>
    </>
  )
}

export default product_engineering
