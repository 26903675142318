import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './style.css';
import banner from "../img/banner/stage_automotive.jpg";
import auto from "../img/banner/automotive.png";
import passive from "../img/banner/passive-safety-grey@3x8510.png";
import passivehover from "../img/banner/passive-safety@3x8510.png";
import active from "../img/banner/active-safety-grey@3x8510.png";
import activehover from "../img/banner/active-safety@3x8510.png";
import steering from "../img/banner/steering-grey@3x8510.png";
import steeringhover from "../img/banner/steering@3x8510.png";
import trans from "../img/banner/transmission-grey@3x8510.png";
import transhover from "../img/banner/transmission@3x8510.png";
import electric from "../img/banner/electric-vehicle-grey@3x8510.png";
import electrichover from "../img/banner/electric-vehicle@3x8510.png";
import body from "../img/banner/body-electronics-grey@3x8510.png";
import bodyhover from "../img/banner/body-electronics@3x8510.png";
import info from "../img/banner/infotainment-grey@3x8510.png";
import infohover from "../img/banner/infotainment@3x8510.png";
import vehicle from "../img/banner/vehicle-telematics-grey@3x8510.png";
import vehiclehover from "../img/banner/vehicle-telematics@3x8510.png";
import { IoIosCheckbox } from "react-icons/io";

function automotive() {
  return (
    <>
       <div className="banner">
      <img src={banner} alt="" width={"100%"} />
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-8 service-content">
            <h4>Solutions for Real Time Complex Systems in Automotive/Transportation domain.</h4>
            <p>We have extensive experience in developing solutions for real time complex systems for our customers in the area of automotive/transportation systems. Our engineers have prior experience of working with end OEM’s, Tier 1 and Tier 2 suppliers and have been involved in all phases of the software development lifecycle starting from requirements elicitation up to system testing in accordance with V Model-Software Development Life Cycle. Our associates are familiar with Autosar Architecture and its related tools used in various phases of software development life cycle.</p>
          </div>
           <div className="col-lg-4 about-img">
            <img src={auto} alt="automotive" width={"100%"} />
          </div>
          </div>
        </div>

        <div className="container-fluid overview-content wow fadeInUp">
		  <div className="row center-xs">
		  <div className="col-xs-12 col-md-4 col-lg-3 text-center">
            <button value="passive"><div className="circle">
                <img className="default-image" src={passive} alt="Passive Safety" width="100%" /> 
                <img className="hover-image" src={passivehover} alt="Passive Safety" width="100%" /> 
                <div className="name">Passive Safety</div></div></button></div> 
		  
		  <div className="col-xs-12 col-md-4 col-lg-3 text-center">
            <button value="active">
                <div className="circle">
                <img className="default-image" src={active} alt="Active Safety" width="100%" /> 
                <img className="hover-image" src={activehover} alt="Active Safety" width="100%" /> 
                <div className="name">Active Safety</div></div></button></div> 
		  
		  <div className="col-xs-12 col-md-4 col-lg-3 text-center">
            <button value="steering"><div className="circle">
                <img className="default-image" src={steering} alt="Steering" width="100%" />
                <img className="hover-image" src={steeringhover} alt="Steering" width="100%" /> 
                <div className="name">Steering</div></div></button></div> 
		  
		  <div className="col-xs-12 col-md-4 col-lg-3 text-center">
            <button value="transmission"><div className="circle">
                <img className="default-image" src={trans} alt="Transmission" width="100%" /> 
                <img className="hover-image" src={transhover} alt="Transmission" width="100%" />  
                <div className="name">Transmission</div></div></button></div>
		  
		  <div className="col-xs-12 col-md-4 col-lg-3 text-center">
            <button value="electric-vehicle">
                <div className="circle">
                <img className="default-image" src={electric} alt="Electric Vehicle" width="100%" /> 
                <img className="hover-image" src={electrichover} alt="Electric Vehicle" width="100%" /> 
                <div className="name">Electric Vehicle</div></div></button></div> 
		  
		  <div className="col-xs-12 col-md-4 col-lg-3 text-center">
            <button value="body-electronics">
                <div className="circle">
                    <img className="default-image" src={body} alt="Body Electronics" width="100%" /> 
                    <img className="hover-image" src={bodyhover} alt="Body Electronics" width="100%" /> 
                    <div className="name">Body Electronics</div>
                    </div>
                    </button>
                    </div> 
		  
		  <div className="col-xs-12 col-md-4 col-lg-3 text-center">
            <button value="infotainment">
                <div className="circle">
                    <img className="default-image" src={info} alt="Infotainment" width="100%" /> 
                    <img className="hover-image" src={infohover} alt="Infotainment" width="100%" /> 
                    <div className="name">Infotainment</div>
                    </div>
                    </button>
                    </div>
		  
		  
		  <div className="col-xs-12 col-md-4 col-lg-3 text-center">
			<button value="Vehicle">
            <div className="circle">
            <img className="default-image" src={vehicle} alt="Vehicle Telematics" width="100%" /> 
            <img className="hover-image" src={vehiclehover} alt="Vehicle Telematics" width="100%" /> 
            <div className="name">Vehicle Telematics</div>
            </div>
            </button>
            </div>
			</div>
		</div>

    <div className="container">
      <div className="row m-t-20 overview-content wow fadeInUp" id="solution">
      <div className="col-xs-12" id="passive">
        <div className="safety">
      <div className="row passive-safety content">
        <div className="col-sm-2 hide-sm">
      <img src={passivehover} alt="Passive Safety" width="100%" />
      </div> 
      <div className="col-lg-12">
    <div className="summary">
    <span className="passive-safety-text"><h2>PASSIVE SAFETY</h2></span> 
    <span className="description">The term “Passive Safety” refers to the curative measures that is taken in an automotive system to ensure the safety of the occupants during a crash. Depending on the severity of the crash the system takes appropriate curative measures to ensure safety of the occupants from critical injury. 
        <br/><br/> The central control unit is responsible for sensing, processing and executing all the needed actions is an Electronic Control Unit (ECU). We support our clients in developing and testing of various software modules of passive safety systems related electronic control units. 
        <br/><br/> We have extensive working knowledge in safety systems software modules like Crash Recorders, Diagnostics, Vehicle Communication Protocol - CAN and Flexray, Fault Diagnostics, Sensor Diagnostics. We perform integration Testing and System Testing of Airbag ECU's and have proficiency in Customer Co-ordination involving software modules of different OEM projects.</span> 
        
        <span className="passive-safety-text"><h3>Activities</h3></span><br />
         
        <ul>
            <li><span><IoIosCheckbox className="custom-icon" />System Requirement Analysis</span></li> 
            <li><IoIosCheckbox className="custom-icon" />Software Requirement Development</li> 
            <li><IoIosCheckbox className="custom-icon" />Software Architecture Development</li> 
            <li><IoIosCheckbox className="custom-icon" />Software Design Development</li> 
            <li><IoIosCheckbox className="custom-icon" />Software Code Development</li> 
            <li><IoIosCheckbox className="custom-icon" />Software Unit Test</li> 
            <li><IoIosCheckbox className="custom-icon" />Software Integration Test</li> 
            <li><IoIosCheckbox className="custom-icon" />Software Requirement Based Testing</li>
            </ul> 
            <span className="passive-safety-text" ><h3>Process</h3></span> 
                <ul>
                <li><IoIosCheckbox className="custom-icon" />ASPICE compliance followed for the SDLC</li> 
                <li><IoIosCheckbox className="custom-icon" />All the code developed and release compliant to MISRA coding rules</li> 
                <li><IoIosCheckbox className="custom-icon" />Every artifact is validated through review process before release</li> 
                <li><IoIosCheckbox className="custom-icon" />Check lists followed at each stage to ensure quality of every artifact</li>
                </ul>
                </div>
                </div>
                </div>
                </div>
                </div>
                </div>
                </div>
                <hr/>

<div className="container">
      <div className="row m-t-20 overview-content wow fadeInUp" id="solution">
      <div className="col-xs-12" id="passive">
        <div className="safety">
      <div className="row passive-safety content">
        <div className="col-sm-2 hide-sm">
      <img src={activehover} alt="Passive Safety" width="100%" />
      </div> 
      <div className="col-xs-12 col-sm-10">
    <div className="summary">
    <span className="passive-safety-text"><h2>ACTIVE SAFETY</h2></span> 
    <span className="description">The term “Active Safety” refers to the preventive measures that is taken in an automotive system necessary to prevent a crash during various dynamic scenario when a vehicle is in motion in different environmental conditions. There are several features which assists the driver for a smooth driving experience and ensuring safety in many scenarios. These includes braking systems, like brake assist, traction control systems and electronic stability control systems. Additionally, forward-looking, sensor-based systems such as Advanced Driver Assistance Systems including adaptive cruise control and collision warning/avoidance/mitigation systems are also considered as active safety systems under this definition. 
        <br/><br/> We support our clients in developing and testing of various software modules of active safety systems related electronic control units. We are involved in the development and testing of safety systems such as ESP, ABS software modules like diagnostics and network communication based on Autosar Architecture in line with ASPICE process.
        <br/><br/>We are also involved in System testing of ABS, ESP modules using Mini Lab Car and Dspace Hardware. We perform complete activities in the Integration of release software & customer Co-ordination involving software modules of different OEM projects.</span> 
        <span className="passive-safety-text"></span>
        <br /><h3>Activities</h3> 
        <ul>
            <li><IoIosCheckbox className="custom-icon" />System Requirement Analysis / (Requirement Engineering)</li> 
            <li><IoIosCheckbox className="custom-icon" />Software Design Development</li> 
            <li><IoIosCheckbox className="custom-icon" />Software Code Development</li> 
            <li><IoIosCheckbox className="custom-icon" />Software Unit Test</li> 
            <li><IoIosCheckbox className="custom-icon" />Software Integration Test</li> 
            <li><IoIosCheckbox className="custom-icon" />Software Requirement Based Testing</li> 
            <li><IoIosCheckbox className="custom-icon" />End of line (EOL) Testing</li> 
            <li><IoIosCheckbox className="custom-icon" />Reprograming Testing (RPG)</li>
            <li><IoIosCheckbox className="custom-icon" />Automation Test</li>
            </ul> 
            <span className="passive-safety-text" ><h3>Process</h3></span> 
                <ul>
                <li><IoIosCheckbox className="custom-icon" />Requirement tracing done as pre SDLC (V-Model)</li> 
                <li><IoIosCheckbox className="custom-icon" />ASPICE compliance followed for the SDLC</li> 
                <li><IoIosCheckbox className="custom-icon" />All the code developed and release compliant to MISRA coding rules</li> 
                <li><IoIosCheckbox className="custom-icon" />Every artifact is validated through review process before release</li>
                <li><IoIosCheckbox className="custom-icon" />SOP Check lists followed at each stage to ensure quality of every artifact</li>
                <li><IoIosCheckbox className="custom-icon" />Static rating</li>
                <li><IoIosCheckbox className="custom-icon" />Customer document preparation</li>
                <li><IoIosCheckbox className="custom-icon" />Baseline Activities are tracked at each of delivery</li>
                <li><IoIosCheckbox className="custom-icon" />Software release to customer (Series Release)</li>
                </ul>
                </div>
                </div>
                </div>
                </div>
                </div>
                </div>
                </div>

                <hr/>
    <div className="container">
      <div className="row m-t-20 overview-content wow fadeInUp" id="solution">
      <div className="col-xs-12" id="passive">
        <div className="safety">
      <div className="row passive-safety content">
        <div className="col-sm-2 hide-sm">
      <img src={steeringhover} alt="Passive Safety" width="100%" />
      </div> 
      <div className="col-xs-12 col-sm-10">
    <div className="summary">
    <span className="passive-safety-text"><h2>STEERING</h2></span> 
    <span className="description">Steering is the major equipment in autonomous car and pacecom have hands on experience in contributing to a research project with a major technology company by supporting them for software development. 
        <br/><br/> pacecom have the expertise to advice and execute the test system required for HIL testing of active safety system that includes active steering systems for HIL testing using VT System.</span> 
        <span className="passive-safety-text"></span> 
    </div></div></div></div></div></div></div>

<hr/>

    <div className="container">
      <div className="row m-t-20 overview-content wow fadeInUp" id="solution">
      <div className="col-xs-12" id="passive">
        <div className="safety">
      <div className="row passive-safety content">
        <div className="col-sm-2 hide-sm">
      <img src={transhover} alt="Passive Safety" width="100%" />
      </div> 
      <div className="col-xs-12 col-sm-10">
    <div className="summary">
    <span className="passive-safety-text"><h2>TRANSMISSION</h2></span> 
    <span className="description">pacecom directly work with transmission manufacturers to develop the ECU for their transmission equipped for OFF high way vehicles. 
        <br/><br/> With the development of the new 8-speed automatic transmission, the focus is not on the number of speeds but rather on the minimization of fuel consumption.
        <br/>8-speed automatic transmission, which was designed to drive the load of 120T have a very complex design of hydraulic which need to be controlled using electromechanical means.
        <br/><br/>Majorly transmission ECU shall have the capability to control the hydraulic circuits to accomplish the automatic transmission the complexity of the transmission depends upon market requirement so as the volume is less and complex is high due to heavy loads upto 120T to be transmitted.</span> 
        <span className="passive-safety-text"></span> 
                </div>
                </div>
                </div>
                </div>
                </div>
                </div>
                </div>

                <hr/>

    <div className="container">
      <div className="row m-t-20 overview-content wow fadeInUp" id="solution">
      <div className="col-xs-12" id="passive">
        <div className="safety">
      <div className="row passive-safety content">
        <div className="col-sm-2 hide-sm">
      <img src={electrichover} alt="Passive Safety" width="100%" />
      </div> 
      <div className="col-xs-12 col-sm-10">
    <div className="summary">
    <span className="passive-safety-text"><h2>ELECTRIC VEHICLE</h2></span> 
    <span className="description">pacecom has worked with customer partners and contributed in the development of electronics used in Electric Vehicles.</span> 
        <span className="passive-safety-text"></span>
        <br />
        <h3>Some of the expertise of pacecom includes</h3> 
        <h3 className="headingCont">Control system architecture</h3> 
        <ul>
            <li><IoIosCheckbox className="custom-icon" />12v / 24 volt control architecture</li> 
            <li><IoIosCheckbox className="custom-icon" />Ground isolation</li> 
            
            </ul> 
            <span className="passive-safety-text" >
            <h3 className="headingCont">Battery management System</h3></span> 
                <ul>
                <li><IoIosCheckbox className="custom-icon" />Passive cell balancing system</li> 
                <li><IoIosCheckbox className="custom-icon" />Scalable energy storage systems</li> 
                </ul>

                <span className="passive-safety-text" >
            <h3 className="headingCont">System Communication</h3></span> 
                <ul>
                <li><IoIosCheckbox className="custom-icon" />High speed CAN</li> 
                <li><IoIosCheckbox className="custom-icon" />High speed data transmission UART, USB,SPI</li> 
                </ul>

                <span className="passive-safety-text" >
            <h3 className="headingCont">Peripheral power management</h3></span> 
                <ul>
                <li><IoIosCheckbox className="custom-icon" />Vehicle environment</li> 
                <li><IoIosCheckbox className="custom-icon" />Drive condition</li> 
                <li><IoIosCheckbox className="custom-icon" />Power pack status</li> 
                </ul>
                </div>
                </div>
                </div>
                </div>
                </div>
                </div>
                </div>

                <hr/>

      <div className="container">
      <div className="row m-t-20 overview-content wow fadeInUp" id="solution">
      <div className="col-xs-12" id="passive">
        <div className="safety">
      <div className="row passive-safety content">
        <div className="col-sm-2 hide-sm">
      <img src={bodyhover} alt="Passive Safety" width="100%" />
      </div> 
      <div className="col-xs-12 col-sm-10">
    <div className="summary">
    <span className="passive-safety-text"><h2>BODY ELECTRONICS</h2></span> 
    <span className="description">In automotive electronics, body control module or 'body computer' is a generic term for an electronic control unit responsible for monitoring and controlling various electronic accessories in a vehicle's body. 
      <br/>pacecom's capability is innovating new features, new use cases and to identify new sensing technology for better user experience.</span> 
        <span className="passive-safety-text"></span>
        <br />
        <h3>Some of the expertise of pacecom includes</h3> 
        <h3 className="headingCont">Over Head Console</h3> 
        <ul>
            <li><IoIosCheckbox className="custom-icon" />LED drivers for internal lighting</li> 
            <li><IoIosCheckbox className="custom-icon" />IR based sunroof and Moon roof control using Neonode</li>
            <li><IoIosCheckbox className="custom-icon" />Piezo based Haptic feed back systems using AITO</li> 
            
            </ul> 
            <span className="passive-safety-text" >
            <h3 className="headingCont">Cabin Lightings</h3></span> 
                <ul>
                <li><IoIosCheckbox className="custom-icon" />LED drivers for internal lighting</li> 
                <li><IoIosCheckbox className="custom-icon" />IR and piezo based light control switches</li> 
                </ul>

                <span className="passive-safety-text" >
            <h3 className="headingCont">OLED Displays</h3></span> 
                <ul>
                <li><IoIosCheckbox className="custom-icon" />Display based switches</li> 
                <li><IoIosCheckbox className="custom-icon" />Piezo based haptic feed back in switches</li> 
                </ul>

                <span className="passive-safety-text" >
            <h3 className="headingCont">Power Windows</h3></span> 
                <ul>
                <li><IoIosCheckbox className="custom-icon" />Anti pinch window system</li> 
                <li><IoIosCheckbox className="custom-icon" />IP64 optical sensor design</li>
                </ul>
                </div>
                </div>
                </div>
                </div>
                </div>
                </div>
                </div>
                
                <hr/>

                <div className="container">
      <div className="row m-t-20 overview-content wow fadeInUp" id="solution">
      <div className="col-xs-12" id="passive">
        <div className="safety">
      <div className="row passive-safety content">
        <div className="col-sm-2 hide-sm">
      <img src={infohover} alt="Passive Safety" width="100%" />
      </div> 
      <div className="col-xs-12 col-sm-10">
    <div className="summary">
    <span className="passive-safety-text"><h2>INFOTAINMENT</h2></span> 
    <span className="description">Today infotainment is a major HMI in automotive and it contributes to great extent to not only entertain but also to navigate and provide V2x connectivity. Features</span> 
        
        <ul>
            <li><IoIosCheckbox className="custom-icon" />Connectivity to multiple USB devices</li> 
            <li><IoIosCheckbox className="custom-icon" />WIFI and BT using UART and PCIe</li>
            <li><IoIosCheckbox className="custom-icon" />VGA integration using USB</li>
            <li><IoIosCheckbox className="custom-icon" />10” touch display using LVDI/HDMI</li> 
            <li><IoIosCheckbox className="custom-icon" />Air quality with alcohol detection can be connected to the systems</li> 
            <li><IoIosCheckbox className="custom-icon" />Mira cast in android</li> 
            <li><IoIosCheckbox className="custom-icon" />Navigation and telematics</li> 
            
            </ul> 
                </div>
                </div>
                </div>
                </div>
                </div>
                </div>
                </div>

    </>
  )
}

export default automotive
