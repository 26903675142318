import * as Yup from "yup";

export const ContactSchema = Yup.object({
  name: Yup.string().min(5).max(25).required("Please enter your name"),
  mobile: Yup.string().matches(/^[0-9]{10}$/) .required('Mobile number is required'),
  email: Yup.string().email().required("Please enter your email"),
  textarea: Yup.string().min(20).max(200).required("Please enter your message"),
    
});

