import React from 'react';
import atomation from '../img/banner/test-atomation.jpg';

function test_automation() {
  return (
    <>
      <div className="banner">
      <img src={atomation} alt="" width={"100%"} />
      </div> 

        <div className="container">
        <div className="row SAP-Overview">
        <div className="col-lg-12 overview-content wow fadeInUp">
          <div className="col-lg-12 service-content">
            <h4>Test Automation</h4>
            <p>We have a deep understanding of the various tools and methodologies applied in Test Automation and the expertise to customize specialized frameworks to execute Regression Test suites in an Agile work environment.</p>
          <p><strong>Test Consultancy</strong> –Our Software Test Consulting services include gated process definition and assessment, tool and automation advisory, audits and quality certification, support in setting up of a test-business and certification for software production.</p>
        
          </div>
          
           <div className="col-lg-12 overview-content wow fadeInUp service-content">
               <p><strong>Performance & Security Testing</strong> – Our teams determine how fast certain areas of a system function under a specific load, performance benchmarks and tests to identify flaws in the security ecosystem of the solution, and data protection while maintaining functionality.</p>
           <p><strong>Device & Game Testing</strong> – We have proven expertise in testing of games and devices, a sound knowledge of platforms such as OpenGL and ActiveX, and issues associated with app/ back-end updates etc.</p>
           <p><strong>COE Services</strong>-We offer comprehensive Center of Excellence (CoE) implementation for enterprises planning to setup and operate CoEs. Our in-house CoE solution has a robust framework that provides cost effectiveness and standardization to ensure stability, availability, and reliability of the product under test.</p>
           </div>
          </div>
        </div>

      </div>
       
    </>
  )
}

export default test_automation
