import React, { useRef } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'aos/dist/aos.css';
import service from '../img/service/business-analyst.png';
import data from '../img/about/data1.jpg'; 
import MKV from '../img/product/MKV.png';
import Entran from '../img/product/Entran.png';
import JustLaw from '../img/product/JustLaw.png';
import reqer from '../img/product/reqer.png';
import jobs2teach from '../img/product/jobs2teach.png';
import logovmt from '../img/product/logo-vmt.png';
import diagram from '../img/logo/diagram.png';
import chart from '../img/logo/chart.png';
import bank from '../img/logo/bank.png';
import { FaCarRear } from "react-icons/fa6";
import { FaDatabase, FaPuzzlePiece, FaExternalLinkAlt } from "react-icons/fa";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navigation, Pagination, Autoplay, A11y } from 'swiper/modules';
import { IoIosArrowForward } from "react-icons/io";
import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
import Slider1 from '../img/slider/PacecomBanner4.png';
import Slider2 from '../img/slider/PacecomBanner5.png';
import Slider3 from '../img/slider/PacecomBanner6.png';
import Slider4 from '../img/slider/PacecomBanner8.jpg';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'swiper/swiper-bundle.css';
import 'react-medium-image-zoom/dist/styles.css';


function Home() {
  const section1 = useRef(null);
  return (
<>

<div id='Home'>
<Swiper
      // install Swiper modules
      modules={[Navigation, Pagination, Autoplay, A11y]}
      spaceBetween={-2}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      autoplay={{ delay: 5500, disableOnInteraction: false }}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}
      loop={true}                                                   
        loopFillGroupWithBlank={true}
    >
      <SwiperSlide><img src={Slider1} alt='slider' style={{width:"100%", height:"10%"}}/>
      <Carousel.Caption className='caroucaption'>
          <h3 className="d-none d-sm-block">PAY-PER-USE PRICING MODEL</h3>
          <p className="d-none d-sm-block">Ability to predict and control costs</p>
          <span className="d-none d-sm-block"><a href="#Services"><Button variant="outline-warning" size="lg">Our Services</Button></a>
          <span style={{margin: "20px"}}><a href="#products"><Button variant="outline-light" size="lg">Our Products</Button></a></span></span>
        </Carousel.Caption>
      </SwiperSlide>
      <SwiperSlide><img src={Slider2} alt='slider' style={{width:"100%", height:"10%"}}/>
      <Carousel.Caption className='caroucaption'>
          <h3 className="d-none d-sm-block">IMPROVED OPERATIONAL EXCELLENCE</h3>
          <p className="d-none d-sm-block">Standardized set of tools/processes using the ITIL framework and committed Service Level Agreements.</p>
          <span className="d-none d-sm-block"><a href="#Services"><Button variant="outline-warning" size="lg">Our Services</Button></a>
          <span style={{margin: "20px"}}><a href="#products"><Button variant="outline-light" size="lg">Our Products</Button></a></span></span>
        </Carousel.Caption></SwiperSlide>
        <SwiperSlide><img src={Slider3} alt='slider' style={{width:"100%", height:"10%"}}/>
      <Carousel.Caption className='caroucaption'>
          <h3 className="d-none d-sm-block">FLEXIBILITY</h3>
          <p className="d-none d-sm-block">Ability to decide the quantity of work and pay accordingly</p>
          <span className="d-none d-sm-block"><a href="#Services"><Button variant="outline-warning" size="lg">Our Services</Button></a>
          <span style={{margin: "20px"}}><a href="#products"><Button variant="outline-light" size="lg">Our Products</Button></a></span></span>
        </Carousel.Caption></SwiperSlide>
    </Swiper>
    </div>

{/* <!-- ======= Counts Section ======= --> */}
<section id="About" className="counts">
  <Container className="aos-init aos-animate" data-aos="fade-up">
    <div className="">
    <div className="heading-text">
      <h2>Ensuring the highest level of customer satisfaction</h2>
      <h2>makes us a better company in our field.</h2>
      <p className="pb-5 pt-3">PACECOM’s core value is the unique understanding of customer’s needs and our ability to strive to offer flexible solutions to our customers by providing our customers with a unique value proposition through exemplary service that guarantees reduced cost and increased revenue.</p>
    </div>

    <div className="heading-text aos-init aos-animate" data-aos="fade-up">
      <h2 className="pb-4">Our Services</h2>
    </div>
   
   <Row>
          
    <Col lg={4} md={6} sm={12} xs={12} className="d-md-flex align-items-md-stretch aos-init aos-animate" data-aos="fade-up">
        <div className="count-box">
        <div className="count-icon">1</div>
         <h5 className="pt-1">Automotive</h5>
          <p className="pt-1">Solutions for Real Time Complex Systems in Automotive/Transportation domain.</p>
          <a className="btn btn-primary btn-readmore" href={`/automotive`}>Know More <IoIosArrowForward /></a>
        </div>
      </Col>

      <Col lg={4} md={6} sm={12} xs={12} className="d-md-flex align-items-md-stretch aos-init aos-animate" data-aos="fade-up">
        <div className="count-box">
        <div className="count-icon">2</div>
          <h5 className="pt-1">SAP Solutions</h5>
          <p className="pt-1">We are a SAP Services Company. PACECOM Technologies has been in SAP practice for the last Five years.</p>
          <a className="btn btn-primary btn-readmore" href="/solutions">Know More <IoIosArrowForward /></a>
        </div>
        </Col>

        <Col lg={4} md={6} sm={12} xs={12} className="d-md-flex align-items-md-stretch aos-init aos-animate" data-aos="fade-up">
        <div className="count-box">
        <div className="count-icon">3</div>
          <h5 className="pt-1">Product Engineering</h5>
          <p className="pt-1">We offer software product design, software systems development services and Centers of Excellence (CoE) for key technology stacks.</p>
          <a className="btn btn-primary btn-readmore" href="/product_engineering">Know More <IoIosArrowForward /></a>
        </div>
        </Col>

        <Col lg={4} md={6} sm={12} xs={12} className="d-md-flex align-items-md-stretch aos-init aos-animate" data-aos="fade-up">
        <div className="count-box">
        <div className="count-icon">4</div>
          <h5 className="pt-1">Mobile Apps & Testing</h5>
          <p className="pt-1">Mobility solutions for the hottest technologies today ushering in a high level of disruption across domains.</p>
          <a className="btn btn-primary btn-readmore" href="/mobile_app_testing">Know More <IoIosArrowForward /></a>
        </div>
      </Col>

      <Col lg={4} md={6} sm={12} xs={12} className="d-md-flex align-items-md-stretch aos-init aos-animate" data-aos="fade-up">
        <div className="count-box">
        <div className="count-icon">5</div>
          <h5 className="pt-1">Test Automation</h5>
          <p className="pt-1">We have a deep understanding of the various tools and methodologies applied in Test Automation and the expertise to customize specialized frameworks to...</p>
          <a className="btn btn-primary btn-readmore" href="test_automation">Know More <IoIosArrowForward /></a>
        </div>
      </Col>
      <Col lg={4} md={6} sm={12} xs={12} className="d-md-flex align-items-md-stretch aos-init aos-animate" data-aos="fade-up">
        <div className="count-box">
        <div className="count-icon">6</div>
          <h5 className="pt-1">Consulting</h5>
          <p className="pt-1">Business enterprises are adopting mobility across the board to drive efficiency, improve customer and employee satisfaction, provide anytime…</p>
          <a className="btn btn-primary btn-readmore" href="/consulting">Know More <IoIosArrowForward /></a>
        </div>
      </Col>
      </Row>
    </div>
  </Container>
</section>
{/* <!-- End Counts Section --> */}

<Container>
  <Row>
  <Col lg={8} md={12} sm={12} xs={12} className="wow fadeInLeft" data-wow-duration="500ms" data-wow-delay="100ms">
  <div className="">
  <div className="left-headline">
	<h3>Our professional services for all business and world wide clients.</h3>
	</div>
  <div className="all-services">
  <Row>
  <Col lg={6} md={12} xs={12}>
	<div className="single-service">
	<div className="service-content">
	<div className="service-icon"><FaCarRear size={24} style={{color: "#fc9f00"}} /></div>
	<div className="service-text">
	<div><a href="#" className="ads" style={{fontWeight: 'bold', fontSize: '18px', fontWeight: 'bold'}}>ADAS & Automated Driving</a></div>
	<p>We’re solving motion control needs for today’s world and an automated future. We relentlessly innovate to enhance safety, control, comfort and performance.</p>
	</div>
	</div>
	</div>
  </Col>

  <Col lg={6} md={12} xs={12}>
	<div className="single-service">
	<div className="service-content">
	<div className="service-icon"><FaDatabase size={24} style={{color: "#fc9f00"}} /></div>
	<div className="service-text">
	<div><a href="#" className="ads" style={{fontWeight: 'bold', fontSize: '18px'}}>Data Annotation</a></div>
	<p>High-quality training and test data is essential in the development and validation of ADAS systems which lay the foundation for autonomous driving technology.</p>
	</div>
	</div>
	</div>
  </Col>

  <Col lg={6} md={12} xs={12}>
	<div className="single-service">
	<div className="service-content">
	<div className="service-icon"><FaPuzzlePiece size={24} style={{color: "#fc9f00"}} /></div>
	<div className="service-text">
	<div className="head-text"><a href="#" className="ads" style={{fontWeight: 'bold', fontSize: '18px', fontWeight: 'bold'}}>Product Development</a></div>
	<p>We have a team of dedicated business development managers responsible for driving a pipeline of new business.</p>
	</div>
	</div>
	</div>
  </Col>

  <Col lg={6} md={12} xs={12}>
	<div className="single-service">
	<div className="service-content">
	<div className="service-icon"><FaExternalLinkAlt size={24} style={{color: "#fc9f00"}} /></div>
	<div className="service-text">
	<div><a href="#" className="ads" style={{fontWeight: 'bold', fontSize: '18px'}}>Outsource</a></div>
	<p>We have a team of dedicated business development managers responsible for driving a pipeline of new business.</p>
	</div>
	</div>
	</div>
  </Col>
  </Row>
  </div>
  </div>
  </Col>
  <Col lg={4} md={12} sm={12} xs={12}>
  <div className="services-images">
  <img src={service} alt="service" width={"100%"} />
  </div>
  </Col>
  </Row>
</Container>

{/* <!-- Start Service area --> */}
		<div className="choose-area area-padding wow fadeInLeft" data-wow-duration="500ms" data-wow-delay="100ms">
			<Container>
        <Row>
				    {/* <!-- Start Column --> */}
            <Col lg={6} md={12} sm={12} xs={12}>
						<div className="choose-wrapper">
							<div className="left-headline">
								<h3>Our Business Is To Make You <span style={{color: "#FFA100"}}>Happy</span></h3>
								<h3>We got <span style={{color: "#FFA100"}}>Skill</span> you can <span style={{color: "#FFA100"}}>Chill</span></h3>
							</div>
							<div className="choose-all">
								<div className="single-choose">
								   <div className="choose-images" to="/#home">
                   <img src={diagram} width={50}/></div> 
									<div className="choose-content">
										<h4><a href="#">Business planning</a></h4>
										<p>Our independent consultants, free from the internal demands of traditional firms, can focus.</p>
									</div>
								</div>
								{/* <!-- Start services --> */}
								<div className="single-choose">
									<a className="choose-images" href="#">
                  <img src={chart} width={40}/></a> 
									<div className="choose-content">
										<h4><a href="#">Online consultant</a></h4>
										<p>Our independent consultants, free from the internal demands of traditional firms, can focus.</p>
									</div>
								</div>
								{/* <!-- Start services --> */}
								<div className="single-choose">
									<a className="choose-images" href="#">
                  <img src={bank} width={40}/></a> 
									<div className="choose-content">
										<h4><a href="#">Bank investment</a></h4>
										<p>Our independent consultants, free from the internal demands of traditional firms, can focus.</p>
									</div>
								</div>
							</div>
						</div>
            </Col>
          {/* <!-- Start Column --> */}
          <Col lg={6} md={12} sm={12} xs={12}>
          <div className="choose-image">
          <img src={data} style={{width:"100%", height:"100%"}} alt="Choose Imagess" />
        </div>
            </Col>
				    {/* <!-- End Column --> */}
        </Row>
      </Container>
		</div>
		{/* <!-- End Service area --> */}


    {/* <!--Start Product Area--> */}
    <section id="Products">
    <Container>
      <Row>
      <h3 className="section-title">Our Products</h3>
        <div className="section-header our-products text-center">
                 
          </div>
            <div id="products">
             <div className="container area-padding wow fadeIn">
               <Row>
                <Col lg={4} md={6} sm={12} xs={12}  className="wow fadeInUp" data-wow-delay="0.2s">
                   <div className="box">
                     <div className="icon"><a href="https://mykidsvan.com/" target="_blank" rel="noreferrer">
                         <img src={MKV} className="img-fluid" alt="" /></a>
                         </div>
                     <h4 className="title"><a href="https://mykidsvan.com/" target="_blank" rel="noreferrer">My Kids Van</a></h4>
                     <p className="description">My Kid's Van brings you a set of specially designed SAFE & SMART solution to track your kids when they travel to school & back</p>
                  
                   </div>
                 </Col>
                 <Col lg={4} md={6} sm={12} xs={12}  className="wow fadeInUp" data-wow-delay="0.2s">
                   <div className="box">
                     <div className="icon"><a href="#"><img src={Entran} className="img-fluid" alt="" /></a></div>
                     <h4 className="title"><a href="#">Enttran</a></h4>
                     <p className="description">Let the country's largest cab provider take care of travel for your organization! The future of corporate travel lies in simplicity and convenience.</p>
                   </div>
                 </Col>
                 <Col lg={4} md={6} sm={12} xs={12}  className="wow fadeInUp" data-wow-delay="0.2s">
                   <div className="box">
                     <div className="icon"><a href="https://www.justlawlegal.com/" target="_blank" rel="noreferrer"> <img src={JustLaw} className="img-fluid" alt="" /></a></div>
                     <h4 className="title"><a href="https://www.justlawlegal.com/" target="_blank" rel="noreferrer">Just Law</a></h4>
                     <p className="description">It's an exclusive application for the legal fraternity which aims at reducing their paperwork and time.</p>
                   </div>
                 </Col>
                 <Col lg={4} md={6} sm={12} xs={12}  className="wow fadeInUp" data-wow-delay="0.2s">
                   <div className="box">
                     <div className="icon"><a href="https://www.reqer.in/lander" target="_blank" rel="noreferrer"><img src={reqer} className="img-fluid" alt="" /></a></div>
                     <h4 className="title"><a href="https://www.reqer.in/lander" target="_blank" rel="noreferrer">Reqer</a></h4>
                     <p className="description">Modernize IT and other business functions with a refreshingly easy-to-use, simple-to-configure IT service desk solution in the cloud.</p>
                   </div>
                 </Col>
                 <Col lg={4} md={6} sm={12} xs={12}  className="wow fadeInUp" data-wow-delay="0.2s">
                   <div className="box">
                     <div className="icon"><a href="https://www.jobs2teach.com/" target="_blank" rel="noreferrer"><img src={jobs2teach} className="img-fluid" alt="" /></a></div>
                     <h4 className="title"><a href="https://www.jobs2teach.com/" target="_blank" rel="noreferrer">Jobs2Teach</a></h4>
                     <p className="description">We, the Jobs2teach team, are highly passionate about building the nation’s intellectual capital on a global scale by uplifting the career opportunities for teachers.</p>
                   </div>
                 </Col>
                 <Col lg={4} md={6} sm={12} xs={12}  className="wow fadeInUp" data-wow-delay="0.2s">
                   <div className="box">
                     <div className="icon"><a href="https://verifymyteacher.com/lander" target="_blank" rel="noreferrer"><img src={logovmt} className="img-fluid" alt="" /></a></div>
                     <h4 className="title"><a href="https://verifymyteacher.com/lander" target="_blank" rel="noreferrer">Verify My Teacher</a></h4>
                     <p className="description">Background Verification ensures that the employee is what they claim to be - personal, educational and experience.</p>
                   </div>
                 </Col>
               </Row>
             </div>
             </div>
      </Row>
    </Container>
  </section>
{/* <!--End Product Area--> */}

  </>
  )
}

export default Home
